#landing-page {
    text-align: center;
    overflow: auto;

    background-color: rgba(0, 0, 0, .5);

    .action-button {
        margin-top: 70px;
    }
}

.landing-logos {
    position: absolute;
    bottom: 30px;
    right: 30px;

    img {
        max-width: 200px;
        margin-left: 60px;
    }
}

#landing-back-link {
    position: absolute;
    top: 15px;
    left: 30px;
}

#landing-chapter-link {
    margin-top: 45px;
}

#landing-credits-link {
    position: absolute;
    bottom: 30px;
    left: 30px;
}

#disclaimer-page {
    background-color: rgba($background-color, .5);
    text-align: center;

    .action-button {
        margin-top: 70px;
    }
}
