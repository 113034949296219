* {
    box-sizing: border-box;
}
html,
body {
    height: 100%;
}

#root,
main {
    display: block;
    width: 100%;
    height: 100%;

    margin: 0;
    padding: 0;

    background-color: #000;

    font-family: $main-font, sans-serif;

    overflow: auto;
}

.page-wrapper {
    position: relative;
    display: flex;
    min-height: 100%;

    z-index: 10;

    .centered-content {
        margin: auto;
        padding-bottom: 15vh;
    }
}
