#home-page {
    position: relative;

    background-image: url('../images/dominik-vanyi-629409-unsplash.jpg');
    background-size: cover;

    /*
    &:before {
        content: ' ';

        position: absolute;
        width: 100%;
        min-height: 100%;
        top: 0;
        left: 0;

        background-color: $background-color;
        opacity: .5;

        z-index: 0;
    } */
}
