#chapters-page {
    flex-direction: column;
    background-color: rgba($background-color, .5);

    h2 {
        text-align: center;
    }

}

#chapters-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    width: calc(100% - 110px);
    margin: 55px;
    padding: 15px;
}


#chapters-back-link {
    position: absolute;
    top: 15px;
    left: 30px;
}
