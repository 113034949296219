.action-button {
    display: inline-block;
    min-width: 232px;
    padding: 8px 15px 6px 15px;

    border: 5px solid $interaction-color;
    background-color: rgba($contrast-color, .2);

    text-align: center;
    text-decoration: none;

    cursor: default;

    .action-button-label {
        color: $interaction-color;

        text-transform: uppercase;

        font-size: 30px;
        font-weight: 700;
    }

    &.disabled {
        border-color: $interaction-disabled-color;

        .action-button-label {
            color: $interaction-disabled-color;
        }
    }
}
