#question-screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    .centered-content {
        text-align: center;
    }
}
