#chapters-row
{
  .overlay {
    display: none;
    position: absolute;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: rgba($background-color, .4);

    opacity: 0;
    transition: $default-transition;
  }

  .card-row
  {
    display: none;
    position: absolute;
    width: 100%;
    height: 208px;

    left: 0;
    bottom: -208px;

    z-index: 9999;

    background: rgba($background-color, .8);
    box-shadow: 0 0 25px 1px $background-color inset;

    transition: $default-transition;
  }


  &.open {
    .overlay {
      display: block;
      opacity: 1;
    }
    .card-row {
      display: flex;
      bottom: 0;
    }
  }
}