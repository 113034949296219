#credits-page {
    background-color: rgba(0, 0, 0, .5);
}

.credits-table {
    width: 100%;
    margin-left: 10px;
    margin-bottom: 50px;
    table-layout: fixed;

    color: $text-color;

    border-spacing: 10px;

    tr {
        width: 100%;

        td {
            &:first-child {
                text-align: right;
                max-width: 200px;
            }

            &:last-child {
                font-weight: bold;
            }
        }
    }
}

.credits-thanks {
    width: 600px;
    margin: auto;

    b {
        //text-transform: capitalize;
    }
}

.credits-logo {
    width: 100%;
    margin-top: 75px;
    text-align: center;

    img {
        max-width: 250px;
        margin: 25px;
    }
}