#player-page {
    width: 100%;
    height: 100%;
    overflow: hidden;

    #player-quit-link {
        position: absolute;
        top: 30px;
        left: 30px;
        z-index: 999;

        .player-quit-btn {
            display: block;
            width: 25px;
            height: 25px;

            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("../icons/Video/Close.png");
        }
    }
}

#video-player-container {
    width: 100%;
    height: 100%;

    background-color: $background-color;

    overflow: hidden;

    .video-frame-container {
        width: 100%;
        height: 100%;

        .youtube-frame-container,
        .youtube-frame {
            display: block;
            width: 100%;
            height: 100%;
        }

        .html-frame-container,
        video {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    &.idle .video-frame-container {
        opacity: .25;
    }
}

.video-controls {
    position: absolute;
    top: calc(50% - 215px);
    left: 0;

    display: flex;
    width: 100%;
    height: 300px;
    justify-content: center;
    align-items: center;
    flex-basis: available;

    background-image: radial-gradient(rgba($background-color, .5) 15%, rgba($background-color, 0) 60%);

    transition: $default-transition;

    opacity: 0;
    transform: scale(.9);

    .show-controls:not(.idle) & {
        opacity: 1;
        transform: scale(1);
    }

    .rewind-btn {
        width: 70px;
        height: 80px;

        background-image: url("../icons/Video/Rewind.png");
    }

    .play-btn {
        width: 90px;
        height: 105px;

        margin: 0 160px;

        &.play {
            background-image: url("../icons/Video/Pause.png");
        }

        &.pause {
            background-image: url("../icons/Video/Play.png");
        }
    }

    .forward-btn {
        width: 70px;
        height: 80px;

        background-image: url("../icons/Video/Forward.png");
    }

    &>* {
        background-size: contain;
        background-repeat: no-repeat;

        transition: $default-transition;

        &:hover {
            transform: scale(1.1);
        }
    }
}

.control-strip {
    position: absolute;
    z-index: 50;
    left: 0;

    width: 100%;
    height: 170px;

    background-image: linear-gradient(to bottom, rgba($background-color, 0), rgba($background-color, .5) 50%);

    transition: $default-transition;

    opacity: 0;
    //bottom: -30px;
    bottom: 0;

    .show-controls &,
    .idle & {
        opacity: 1;
        bottom: 0;
    }

    .progress-position {
        display: flex;
        margin-left: 95px;
        width: calc(100% - 150px);
        margin-top: 80px;
        align-items: center;

        .current-timestamp,
        .video-duration {
            display: block;
            padding: 0 5px;
            width: 60px;

            flex-shrink: 0;
            flex-grow: 1;
            text-align: center;
        }

        .video-progress {
            width: 100%;
            height: 5px;
            margin: 0 30px 2px;

            border-radius: 3px;

            background-color: $emphase-light-color;

            .current-bar {
                height: 5px;
                border-radius: 3px;

                background-color: $emphase-intense-color;
                transition-duration: .25s;
            }
        }
    }

    .idle & {
        .progress-position {
            .current-timestamp,
            .video-duration {
                opacity: 0;
            }
        }
    }

    .video-information {
        position: absolute;
        width: 400px;
        top: 110px;
        left: calc(50% - 200px);

        text-align: center;

        .video-controls-chapter-name {
            margin-top: -7px;
        }
    }

    .chapters-btn {
        position: absolute;
        right: 150px;
        bottom: 20px;
        padding: 3px 30px;
        color: $emphase-intense-color;
        border: 3px solid $emphase-intense-color;
    }
}
