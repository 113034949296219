// COLORS
$clear-color: #FFFFFF;
$contrast-color: #000000;
$emphase-intense-color: #F69640;
$emphase-light-color: #FCDBBF;

// SPECIFIC COLORS
$background-color: $contrast-color;
$text-color: $clear-color;
$interaction-color: $emphase-intense-color;
$interaction-disabled-color: #AAAAAA;

// FONT

$main-font: "Hind";

// TRANSITION

$default-transition-duration: .15s;
$default-transition: $default-transition-duration ease-out;
