#end-page {
  text-align: center;
  overflow: auto;

  background-color: rgba(0, 0, 0, .5);

  .action-button {
    margin-top: 70px;
  }
}

#end-back-link {
  position: absolute;
  top: 15px;
  left: 30px;
}

#end-chapter-link {
  display: block;
  margin-top: 45px;
}

#end-credits-link {
  position: absolute;
  bottom: 15px;
  right: 30px;
}
