.radio-list {
    margin: 20px 0 30px;
    text-align: center;
    .radio-list-item {
        input {
            display: none;
        }

        label {
            display: inline-block;
            width: 550px;
            min-height: 72px;
            margin: 10px 0;

            background-color: #FFFFFF;
            border: 5px solid #FFF;
            border-radius: 6px;

            padding: 15px 20px;
            text-align: left;

            font-size: 24px;
            font-weight: 700;
            text-transform: uppercase;
        }

        input:checked + label {
            border-color: $emphase-intense-color;
            color: $emphase-intense-color;
        }
    }
}
