.video-card {
    display: block;
    position: relative;

    flex-shrink: 0;
    flex-grow: 0;

    width: 300px;
    height: 175px;

    margin: 15px;

    background-color: #000;
    border-radius: 8px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, .7);

    color: #FFF;
    text-align: center;

    overflow: hidden;

    .video-background {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;

        background-size: cover;
        background-position: center center;

        opacity: .65;
    }

    .chapter-number {
        display: block;
        position: relative;
        z-index: 50;

        width: 100%;
        margin-top: 55px;
    }

    .chapter-name {
        display: block;
        z-index: 50;
        position: relative;
        width: 100%;
    }

    .play-btn {
        display: none;
        position: relative;
        z-index: 60;
        width: 100%;
        height: 100%;

        background-image: url("../icons/Video/Play-Small.png");
        background-position: center center;
        background-repeat: no-repeat;
    }

    &:hover {
        .video-background {
            opacity: .5;
        }

        .chapter-number,
        .chapter-name {
            display: none;
        }

        .play-btn {
            display: block;
        }
    }
}
