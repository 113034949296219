@import url('https://fonts.googleapis.com/css?family=Hind:400,700');

h1 {
    width: 100%;

    color: $text-color;

    text-align: center;
    text-transform: uppercase;

    font-size: 72px;
    font-weight: 700;
    line-height: 77px;
}

h2 {
    width: 100%;
    margin-bottom: 0;

    color: $text-color;

    text-align: center;
    text-transform: uppercase;

    font-size: 36pt;
    font-weight: 700;
}

h3 {
    width: 100%;
    margin-bottom: 0;

    color: $text-color;

    text-align: center;
    text-transform: uppercase;

    font-size: 24pt;
    font-weight: 700;
}

p {
    width: 650px;

    color: $text-color;

    font-size: 18pt;

    &.center {
        text-align: center;
    }

    &.left {
        text-align: left;
        width: 500px;
    }
}

.chapter-button-label {
    color: $interaction-color;

    text-transform: uppercase;

    font-size: 15pt;
}

.video-controls-chapter-number {
    color: $emphase-light-color;

    font-size: 12pt;
}

.video-controls-chapter-name {
    color: $emphase-light-color;

    font-size: 14pt;
    font-weight: 700;
}

.video-controls-timecode{
    color: $emphase-intense-color;

    font-size: 14pt;

    letter-spacing: .5ch;
}

.navigation-link {
    display: inline-block;

    text-transform: uppercase;
    text-decoration: none;

    font-size: 20pt;
    color: $text-color;


    &.bordered {
        padding: 5px 20px 2px;
        border: 2px solid #FFF;
    }
}

.video-card-chapter-number {
    color: $text-color;

    font-size: 16pt;

    .video-card:hover & {
        color: $interaction-color;
    }
}

.video-card-chapter-name {
    color: $text-color;

    font-size: 18pt;
    font-weight: 700;

    .video-card:hover & {
        color: $interaction-color;
    }
}

.selectable-item-label {
    color: $contrast-color;

    text-align: left;
    text-transform: uppercase;

    font-size: 24pt;
    font-weight: 700;

    *:checked + .selectable-item & {
        color: $emphase-intense-color;
    }
}
